import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { SectionTitle } from "../components/styled"
import WrapperRight from "../components/WrapperRight"
import JobContent from "../components/JobContent"
import SearchJob from "../components/SearchJob"
import useJobs from "../hooks/useJobs"
import { FaSearch } from "react-icons/fa"

const JobsSearch = props => {
  const dataJobs = useJobs("Japanese")
  const typeName = props.data.allStrapiType.nodes[0].name
  const [dataFilter, setDataFilter] = useState([])
  useEffect(() => {
    dataJobs.forEach(element => {
      element.types.forEach(sameMark => {
        if (sameMark.name === typeName) {
          setDataFilter(prevState => [...prevState, element])
        }
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <section className="container jobs-listing">
        <div className="row">
          <div className="col-lg-9">
            <div className="jobs-listing__search">
              <SectionTitle>メキシコ求人・就職・転職情報</SectionTitle>
              <hr className="u-line-bottom" />
              <div className="jobs-listing__job-search">
                <span>検索条件:</span>
                <Link to={`/jobs-search/${typeName}`}>{typeName}</Link>
              </div>
              <p className="u-color-danger" style={{ marginRight: "0.5em" }}>
                検索条件に合う求人は {dataFilter.length} 件ありました。
              </p>
              <div className="jobs-listing__search--box">
                <SearchJob />
              </div>
            </div>
            <div className="jobs-listing__job-info">
              <hr className="u-line-bottom" />
              {dataFilter.length === 0 ? (
                <div className="no-results">
                  <FaSearch />
                  <p>結果がありません</p>
                </div>
              ) : (
                <>
                  {dataFilter.map(jobs => (
                    <JobContent jobs={jobs} />
                  ))}
                </>
              )}
            </div>
          </div>
          <WrapperRight />
        </div>
      </section>
    </>
  )
}

export default JobsSearch

export const query = graphql`
  query($slug: String) {
    allStrapiType(filter: { name: { eq: $slug } }) {
      nodes {
        name
      }
    }
  }
`
